import { apiPostRequest, apiGetRequest } from "./api"; // eslint-disable-line

export default {
    state: {
        patient: null
    },
    mutations: {
        setPatient(state, data) {
            state.patient = data;
        }
    },
    actions: {
        async loadPatient({ commit }, id) {
            await apiGetRequest("patients/" + id).then((data) => {
                commit("setPatient", data);
            });
        },
        async saveSettings({ commit }, arr) { // eslint-disable-line
            await apiPostRequest("update/patient/settings/" + arr[0], arr[1]);
        }
    },
    getters: {
        getPatient: state => state.patient
    }
}