<template>
    <div class="w-full mt-16 bg-white flex flex-col items-center">
        <div>{{ $t('info.logout') }}</div>
        <div>
            <font-awesome-icon icon="circle-notch" class="mx-2 text-blue" spin size="2x" />
        </div>
    </div>
</template>

<script>
export default {
    name: "Logout",
    mounted() {
        this.$store.dispatch("logout")
            .then(() => {
                this.$router.push("/login");
            }).catch((err) => {
                console.info(err);
            });
    },
}
</script>