import { format } from 'date-fns'
import { sv } from 'date-fns/locale'

const cardDate = (date) => {
    return format(date, "iiii d LLLL, yyyy", {
        locale: sv
    })
}

const dateNow = (date) => {
    return format(date, "yyyy-MM-dd")
}

export {
    cardDate,
    dateNow
}

