<template>
  <div style="position: relative;">
    <div v-if="showHelp" class="textBubble">
      <p class="title">{{ title }}</p>
      <span
        style="position: absolute; right: 10px; top: 5px; cursor: pointer; font-size: 24px;"
        v-on:click="showHelp = false"
      >&times;</span>
      <p class="text">{{ text }}</p>
    </div>
    <div
      v-on:click="showHelp = !showHelp"
      class="w-5 h-5 text-center text-blue-500 cursor-pointer"
    ><font-awesome-icon icon="info-circle"/></div>
  </div>
</template>
<script>
export default {
  name: "BaseTooltip",
  props: {
    title: String,
    text: String
  },
  data() {
    return {
      showHelp: false
    };
  }
};
</script>
<style scoped>
.textBubble {
  background-color: #1467c8;
  width: 300px;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  bottom: 36px;
  left: -46px;
  z-index: 999999999;
  box-shadow: 0px 0px 3px 0px black;
}
.title {
  font-size: 16px;
  font-weight: bold;
  padding: 14px;
}
.text {
  font-size: 14px;
  padding: 0px 14px 14px 14px;
  font-weight: lighter;
}
.textBubble::after {
  content: "";
  position: absolute;
  left: 48px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 12px solid #1467c8;
  clear: both;
}
</style>