<template>
  <div>
    <div class="h-2 w-full bg-base-gray"></div>
    <div class="mb-16" v-if="loaded">
      <p class="font-bold text-xl text-base-gray-text m-6">{{$t("files.document")}}</p>
      <p class="mx-6 my-2 text-base-gray-text font-semibold">{{$t("files.program")}}</p>
      <div class="linkList flex flex-col mx-6 my-2">
        <div v-for="(file, index) in files" :key="index" class="link flex my-2 items-center">
          <img class="h-4 mr-2" src="/images/download.png" />
          <a href="#" class="font-semibold text-base-blue">{{ file.title }}</a>
        </div>
      </div>
      <p class="mx-6 my-3 text-base-gray-text font-semibold">{{$t("files.general")}}</p>
      <div class="linkList flex flex-col mx-6 my-2">
        <div class="link flex my-2 items-center">
          <img class="h-4 mr-2" src="/images/externallink.png" alt srcset />
          <a href="https://www.socialstyrelsen.se/utveckla-verksamhet/evidensbaserad-praktik/metodguiden/dbt-dialektisk-beteendeterapi/" target="_blank" class="font-semibold text-base-blue">{{$t("files.info")}}</a>
        </div>
        <div class="link flex my-2 items-center">
          <img class="h-4 mr-2" src="/images/download.png" alt srcset />
          <a href="#" class="font-semibold text-base-blue">{{$t("files.exercises")}}</a>
        </div>
      </div>
    </div>
        <div v-else class="flex justify-center">
      <font-awesome-icon icon="circle-notch" class="m-2 text-blue" spin size="2x" />
    </div>
  </div>
</template>
<script>
export default {
  name: "Files",
  data() {
      return {
          loaded: false,
          files: [
              {
                  id: 1,
                  title: "Vecka 1 - Lorem Ipsum"
              },
              {
                  id: 2,
                  title: "Vecka 2 - Lorem Ipsum"
              },
              {
                  id: 3,
                  title: "Vecka 3 - Lorem Ipsum"
              }
          ]
      }
  },
  created() {
      this.loaded = true;
  },
};
</script>