import '@babel/polyfill';
import "../fa.config";
import Vue from 'vue'
import App from './App.vue'
import VueI18n from 'vue-i18n';
import Vuex from 'vuex';
import axios from 'axios';
import { router } from '@/router/router.js';

import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const token = localStorage.getItem('user-token')
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer: ${token}`
}

Vue.config.productionTip = false

Vue.use(Vuex);
import appStore from '@/store/store';
const store = new Vuex.Store(
  appStore
);

import '@/assets/style/app.css'
import 'quill/dist/quill.core.css';
import messages from '@/assets/i18n/messages.js'

Vue.use(VueI18n);
const locale = localStorage.getItem('user-locale') || 'sv';

const i18n = new VueI18n({
  locale: locale,
  messages
})

Vue.prototype.$http = axios;

const requireComponent = require.context(
  './components/base/',
  true, //subfolders
  /Base[A-Z]\w+\.(vue|js)$/ // The regular expression used to match base component filenames
)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)

  const componentName = upperFirst(
    camelCase(
      fileName.replace(/^\.\/(.*)\.\w+$/, '$1')
    )
  )

  Vue.component(
    componentName,
    componentConfig.default || componentConfig
  )
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
