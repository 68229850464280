<template>
  <div id="app" class="font-sans select-none">
    <div class="h-full flex-col bg-white" @click="closeSidebar">
      <Header v-if="authenticated"/>
      <router-view id="view" class="bg-white"></router-view>
    </div>
    <SidebarToggle v-if="authenticated"/>
    <Sidebar v-if="authenticated"/>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import SidebarToggle from "@/components/base/SidebarToggle.vue";
import Sidebar from "@/components/base/Sidebar.vue";
import { isPast } from 'date-fns'
export default {
  name: "App",
  components: {
    Header,
    SidebarToggle,
    Sidebar
  },
  mounted() {
    this.checkJwtExpiration();
  },
  computed: {
    authenticated () {
      return this.$store.getters.isAuthenticated;
    }
  },
  methods: {
    closeSidebar() {
      this.$store.dispatch('closeSidebar');
    },
      checkJwtExpiration () {
        return this.$store.dispatch('checkJwtExpiration')
          .then((exp) => {
            let d = new Date(exp*1000);
            if (isPast(d)) {
              this.$store.dispatch('logout')
            }
          })
          .catch(() => {

          })

    },
  }
};
</script>

<style>
#view {
  height: calc(100vh - 4.5rem);
}
</style>
