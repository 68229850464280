<template>
  <div>
    <div v-if="loaded">
      <div  v-if="Object.keys(currentCards).length !== 0">
      <div
        id="weekHeader"
        class="bg-base-gray w-full h-16 text-base-gray-text font-bold text-sm flex text-left items-end"
      >
        <p class="p-2">{{$t("weeklycards.week", [meta.currentWeek])}}</p>
      </div>
      <div id="weekList" class="mb-16">
        <DayItem v-for="(card, index) in currentCards" :key="index" :card="card" :id="index" />
      </div>
    </div>
      <div v-else>
        <div class="flex flex-col items-center m-6">
          <p class="font-bold text-2xl text-base-gray-text">{{$t("weeklycards.noactiveperiod")}}</p>
          <font-awesome-icon class="text-base-gray-text" icon="calendar-times" size="4x"/>
        </div>
      </div>
      </div>
    <div v-else class="flex justify-center">
      <font-awesome-icon icon="circle-notch" class="mx-2 text-blue" spin size="2x" />
    </div>
  </div>
</template>
<style scoped>
</style>
<script>
import DayItem from "@/components/DayItem";
export default {
  name: "WeeklyCards",
  components: {
    DayItem
  },
  data() {
    return {
      loaded: false
    };
  },
  created() {
    this.$store.dispatch("loadMeta");
    this.$store.dispatch("loadCurrentWeeklyCards").then(() => {
      this.loaded = true;
    });
  },
  computed: {
    currentCards() {
      return this.$store.getters.getCurrentWeeklyCards || {};
    },
    meta() {
      return this.$store.getters.getMeta || {};
    }
  },
  methods: {}
};
</script>