<template>
  <div class="bg-white flex flex-col items-center w-full">
    <div class="mt-6">
        <img class="h-16 mb-10" src="/images/kanslobarometern.png" />
    </div>

    <div class="flex flex-col items-center">

        <div
          v-if="errorMsg"
          class="my-2 p-2 w-4/5 rounded-lg border border-red-600 bg-red-100"
        >
          <span>{{ errorMsg }}</span>
        </div>

        <div class="my-2 w-4/5">
          <BaseInputSsn v-model="nationalId" @validationStatus="setButtonStatus" />
        </div>

        <div class="w-4/5">
          <button
            class="w-full rounded-lg px-4 py-2 text-center flex items-center justify-between"
            :class="buttonDisabled"
            @click="loginBankId"
            :disabled="!validNationalId"
          >
            {{ $t('login.bankid') }}
            <img src="@/assets/icon_64x64.png" width="32" height="32" />
          </button>
        </div>

        <div class="w-3/5 m-2 text-center mx-10 text-base-gray-text">
          <p>{{ $t("login.text") }}</p>
        </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  props: ["errorMsg"],
  data() {
    return {
      nationalId: null,
      validNationalId: false,
      sessionId: undefined,
      timer: null,
      numTries: 0,
      error: this.errorMsg,
    };
  },
  computed: {
    url() {
      return process.env.VUE_APP_API_URL;
    },
    buttonDisabled() {
      return !this.validNationalId
        ? "text-white bg-login-invalid"
        : "text-white bg-login-valid";
    },
  },
  watch: {
    numTries(val) {
      if (val >= 10) {
        clearInterval(this.timer);
        this.error = this.$t("login.error.eidentity");
      }
    },
  },
  methods: {
    checkLoginStatus() {
      this.$store
        .dispatch("verifyBankIdLogin", this.sessionId)
        .then(() => {
          this.$router.push("/weeklycards");
        })
        .catch(() => {
          this.$router.push({
            name: "login",
            params: { errorMsg: this.$t("login.error.unauthorized") },
          });
        });
    },
    loginBankId() {
      this.validNationalId = false;
      this.timer = null;
      this.numTries = 0;
      this.error = null;
      this.sessionId = null;
      // 1. create login request from e-id and receive an autoStartToken
      // 2. open bankid-application on the device and let user authenticate
      // 3. attach a listener to window focus, which runs when user returns to this application from bank-id
      // 4. in the listener, at regular intervals, check the session on e-id.
      //   if session is good, continue with user login

      this.$store
        .dispatch("loginBankId", this.nationalId)
        .then((data) => {
          const redirectUrl = data.redirectUrl;
          const autoStartToken = data.autoStartToken;

          if (autoStartToken && autoStartToken !== null) {
            this.sessionId = data.sessionId;
            this.timer = setInterval(() => {
              this.checkLoginStatus();
              this.numTries++;
            }, 2000);

            window.location = `bankid:///?autoStartToken=${autoStartToken}&redirect=null`;
          } else if (redirectUrl && redirectUrl !== null) {
            this.$store.dispatch("setSessionId", data.sessionId);
            self.location.href = redirectUrl;
          } else {
            this.error = "A general login error has occured. Please try again.";
          }
        })
        .catch(() => {
          this.error = "Bank id login error. Please try again.";
        });
    },
    setButtonStatus(valid) {
      this.validNationalId = valid;
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
