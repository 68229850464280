import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
    faSpinner,
    faChevronDown,
    faChevronUp,
    faChevronLeft,
    faChevronRight,
    faCircleNotch,
    faInfoCircle,
    faTimes,
    faCalendarTimes,
    faFileAlt
} from '@fortawesome/free-solid-svg-icons'

library.add(
    faSpinner,
    faChevronDown,
    faChevronUp,
    faChevronLeft,
    faChevronRight,
    faCircleNotch,
    faInfoCircle,
    faTimes,
    faCalendarTimes,
    faFileAlt
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
