<template>
  <div>
    <input
      type="number"
      @keyup="filterValue"
      :maxlength="max"
      :placeholder="placeholder"
      class="w-full rounded-lg border-2 border-grey-light px-2 py-2 text-center tracking-normal focus:outline-none"
      :class="validityClass"
      v-model="filteredSsn"
    />
  </div>
</template>

<script>
import personnummer from "personnummer";

export default {
  name: "BaseInputSsn",
  props: ["value"],
  data() {
    return {
      filteredSsn: null,
      valid: null,
    };
  },
  mounted() {},
  watch: {
    filteredSsn: {
      // immediate: true,
      handler() {
        this.$emit("input", this.computedNationalId);
      },
    },
    valid(value) {
      this.$emit("validationStatus", value);
    },
  },
  methods: {
    filterValue(e) {
      let ssn = e.target.value;
      let valid = personnummer.valid(ssn);

      if (valid) {
        let parseSsn = personnummer.parse(ssn);
        this.filteredSsn = parseSsn.format(true);
      }

      this.valid = valid;
    },
  },
  computed: {
    computedNationalId() {
      return this.filteredSsn;
    },
    placeholder() {
      return this.$t("placeholder.input.ssn");
    },
    max() {
      return 13;
    },
    validityClass() {
      return {
        "border-2": this.valid === true,
        "border-green-700": this.valid === true,
        "bg-green-100": this.valid === true,
        "border-red-700": this.valid === false,
        "bg-red-100": this.valid === false,
      };
    },
  },
};
</script>

