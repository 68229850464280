<template>
  <div>
    <div class="h-2 w-full bg-base-gray"></div>
    <div class="mb-16" v-if="loaded">
      <div class="flex flex-col" v-if="patient.contactInfo != '<p><br></p>' && patient.contactInfo">
        <div class="ql-editor" v-html="patient.contactInfo"></div>
      </div>
      <div v-else class="text-center p-4">
        <p>{{ $t("information.empty") }}</p>
        <font-awesome-icon icon="file-alt" class="text-gray-400 m-4" size="4x" />
      </div>
    </div>
    <div v-else class="flex justify-center">
      <font-awesome-icon icon="circle-notch" class="m-2 text-blue" spin size="2x" />
    </div>
  </div>
</template>
<script>
export default {
  name: "Information",
  data() {
    return {
      loaded: false,
      patient: null,
    };
  },
  created() {
    this.$store
      .dispatch("loadPatient", this.$store.getters.patientId)
      .then(() => {
        this.patient = this.$store.getters.getPatient;
        this.loaded = true;
      });
  },
};
</script>