<template>
  <div v-if="showSidebarToggle" class="w-full fixed bottom-0 p-3 border-t bg-white">
    <div class="flex flex-col items-center" @click="toggleSidebar">
      <button class>
        <img class="w-8" src="@/assets/hamburger.svg" />
      </button>
      <span class="text-sm">{{ $t('button.menu') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SidebarToggle",
  computed: {
    showSidebarToggle() {
      return this.$store.getters.showSidebarToggle;
    }
  },
  methods: {
    toggleSidebar() {
      this.$store.dispatch("toggleSidebar");
    }
  }
};
</script>