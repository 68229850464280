<template>
  <div class="py-4 px-4 w-full">
    <div class="text-left font-bold text-grey-darkest">
      <label class="flex">
        <p>
          {{ question.title }}
          <span
            v-if="question.optional"
            class="font-thin text-sm italic mx-1"
          >- {{$t('inputs.optional')}}</span>
        </p>
        <component
          v-if="question.help"
          :is="'BaseTooltip'"
          :title="question.title"
          :text="question.help"
        />
      </label>
    </div>
    <div id="multiRadio" class="flex flex-col pt-4">
      <div v-for="(option, index) in question.options" :key="index" class="radioButton">
        <label
          class="text-left font-bold text-grey-darkest radioLabel"
          :for="('multiRadio'+question.id)+option.value"
        >
          {{ option.label }}
          <input
            type="radio"
            v-model="value"
            :name="'multiRadio'+question.id"
            :id="('multiRadio'+question.id)+option.value"
            :value="option.value"
            @change="updateAnswer()"
          />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
    <div v-if="question.comment" id="comment" class="text-left py-2">
      <input
        type="text"
        v-model="commentValue"
        class="w-full border border-grey-dark h-10 rounded-sm p-2"
        :placeholder="$t('inputs.comment')"
        @blur="updateAnswer()"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseMultipleRadio",
  data() {
    return {
      id: this.question.id,
      value: this.question.values ? this.question.values.value : null,
      commentValue: this.question.values ? this.question.values.comment : null,
      type: this.question.values ? this.question.values.type : null
    };
  },
  created() {
    this.updateAnswer();
  },
  methods: {
    updateAnswer() {
      let answer = {
        id: this.id,
        value: this.value,
        comment: this.commentValue,
        type: this.type
      };
      this.$emit("updateAnswer", answer);
    }
  },
  props: {
    question: {
      formId: String,
      id: Number,
      title: String,
      optional: Boolean,
      comment: Boolean,
      help: String,
      options: Array,
      values: Object
    }
  }
};
</script>
<style scoped>
/* The radioLabel */
.radioLabel {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  line-height: 25px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default radio button */
.radioLabel input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #777777;
}

/* On mouse-over, add a grey background color */
.radioLabel:hover input ~ .checkmark {
  background-color: #eee;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radioLabel input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radioLabel .checkmark:after {
  top: 3px;
  left: 3px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #4990e2;
}
</style>