<template>
  <div class="py-4 px-4 w-full">
    <div class="text-left font-bold text-grey-darkest">
      <label class="flex">
        <p>
          {{ question.title }}
          <span v-if="question.optional" class="font-thin text-sm italic mx-1"
            >- {{ $t("inputs.optional") }}</span
          >
        </p>
        <component
          v-if="question.help"
          :is="'BaseTooltip'"
          :title="question.title"
          :text="question.help"
        />
      </label>
    </div>
    <div id="slider">
      <div class="mr-2">{{ question.min }}</div>
      <div class="w-full mx-2">
        <vue-slider
          ref="test"
          v-model="value"
          :min="question.min"
          :max="question.max"
          :dotSize="25"
          :interval="1"
          :tooltip="'none'"
          v-on:touchstart="focusSlider()"
        />
      </div>
      <div class="mx-2">{{ question.max }}</div>
      <div>
        <input
          type="number"
          v-model="value"
          class="ml-2 border border-grey-dark w-12 h-10 rounded-sm text-center"
          name
          id="sliderNumber"
        />
      </div>
    </div>
    <div v-if="question.comment" class="comment text-left py-2">
      <input
        type="text"
        v-model="commentValue"
        class="w-full border border-grey-dark h-10 rounded-sm p-2"
        :placeholder="$t('inputs.comment')"
        @blur="updateAnswer()"
      />
    </div>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "@/assets/style/slider.css";

export default {
  name: "BaseSlider",
  components: {
    VueSlider,
  },
  data() {
    return {
      id: this.question.id,
      value: this.question.values ? this.question.values.value : null,
      commentValue: this.question.values ? this.question.values.comment : null,
      type: this.question.values ? this.question.values.type : null,
    };
  },
  watch: {
    value: function() {
      if (this.value < this.question.min) {
        this.value = this.question.min;
      } else if (this.value > this.question.max) {
        this.value = this.question.max;
      }
      this.updateAnswer();
    },
  },
  created() {
    this.updateAnswer();
  },
  methods: {
    updateAnswer() {
      let answer = {
        id: this.id,
        value: this.value,
        comment: this.commentValue,
        type: this.type,
      };
      this.$emit("updateAnswer", answer);
    },
    focusSlider() {
      this.$refs.test.focus();
    },
  },
  props: {
    question: {
      formId: String,
      id: Number,
      title: String,
      optional: Boolean,
      comment: Boolean,
      help: String,
      values: Object,
      min: Number,
      max: Number,
    },
  },
};
</script>
<style scoped>
#slider {
  color: #aaaaaa;
  padding: 10px 0px 5px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#slider > span {
  padding: 10px;
}
#sliderNumber {
  font-weight: bold;
  font-size: 20px;
  color: #4182cc;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
