<template>
  <div class="fixed bg-white shadow-lg top-0 left-0 h-full" v-if="showSidebar">
    <div class="h-full flex flex-col text-left justify-between">
      <div>
        <ul class="text-base-gray-text pt-10">
          <li class="px-10 py-10">{{ fullName }}</li>
          <li class="my-3 py-2">
            <router-link
              class="font-bold px-10 py-2 my-3 hover:text-base-blue cursor-pointer"
              :class="isCurrentRoute('card')"
              :to="{ name: 'card', params: { dateId: todayDateId } }"
            >
              {{$t("sidebar.report")}}
            </router-link>
          </li>
          <li class="my-3 py-2">
            <router-link 
              class="font-bold px-10 py-2 my-3 hover:text-base-blue cursor-pointer" 
              :class="isCurrentRoute('weeklyCards')" 
              :to="{ name: 'weeklyCards' }"
            >
              {{$t("sidebar.weeklycards")}}
            </router-link>
          </li>
          <!-- <li
            class="font-bold px-10 py-2 my-3 hover:text-base-blue cursor-pointer"
            :class="isCurrentRoute('files')"
          >
            <router-link :to="{ name: 'files' }">{{$t("sidebar.files")}}</router-link>
          </li> -->
          <li class="my-3 py-2">
            <router-link
              class="font-bold px-10 py-2 my-3 hover:text-base-blue cursor-pointer"
              :class="isCurrentRoute('information')"
              :to="{ name: 'information' }"
            >
              {{$t("sidebar.information")}}
            </router-link>
          </li>
          <li class="my-3 py-2">
            <router-link
              class="font-bold px-10 py-2 my-3 hover:text-base-blue cursor-pointer"
              :class="isCurrentRoute('settings')"
              :to="{ name: 'settings' }"
            >
              {{$t("sidebar.settings")}}
            </router-link>
          </li>
        </ul>
      </div>
      <div class="w-full text-center font-bold p-6">
        <router-link
          class="p-6 text-base-gray-text hover:text-base-blue cursor-pointer"
          :to="{ name: 'logout' }"
        >
          {{$t("sidebar.logout")}}
        </router-link>
      </div>
    </div>
    <div class="fixed bottom-0 right-0">
      <div class="flex flex-col items-center p-2" @click="closeSidebar">
        <img class="w-6" src="/images/times.png" alt srcset />
        <span class="text-xs font-semibold">{{$t("sidebar.close")}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { dateNow } from "@/utils/dateformat";
export default {
  name: "Sidebar",
  computed: {
    showSidebar() {
      return this.$store.getters.isSidebarOpen;
    },
    todayDateId() {
      return dateNow(Date.now());
    },
    fullName() {
      return (
        this.$store.getters.userInfo.first_name +
        " " +
        this.$store.getters.userInfo.last_name
      );
    },
  },
  watch: {
    $route() {
      if (this.$route.name === "card") {
        this.$store.dispatch("hideSidebarToggle");
      } else {
        this.$store.dispatch("showSidebarToggle");
      }

      this.closeSidebar();
    },
  },
  methods: {
    closeSidebar() {
      this.$store.dispatch("closeSidebar");
    },
    isCurrentRoute(to) {
      return this.$route.name === to ? "selected" : "";
    },
  },
};
</script>
<style scoped>
.selected {
  background: linear-gradient(
    90deg,
    rgba(73, 144, 226, 1) 4%,
    rgba(255, 255, 255, 1) 4%
  );
  color: #4990e2;
}
</style>