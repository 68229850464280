<template>
  <div class="py-4 px-4 w-full">
    <div class="text-left font-bold text-grey-darkest">
      <label class="flex">
        <p>
          {{ question.title }}
          <span
            v-if="question.optional"
            class="font-thin text-sm italic mx-1"
          >- {{$t('inputs.optional')}}</span>
        </p>
        <component
          v-if="question.help"
          :is="'BaseTooltip'"
          :title="question.title"
          :text="question.help"
        />
      </label>
    </div>
    <div class="bdtTextarea py-2 text-left">
      <textarea
        v-model="value"
        class="border border-grey-dark w-full rounded-sm text-left h-24 p-2"
        :placeholder="question.placeholder"
        @blur="updateAnswer()"
      ></textarea>
    </div>
  </div>
</template>
<script>
export default {
  name: "BaseDbtTextarea",
  data() {
    return {
      id: this.question.id,
      value: this.question.values ? this.question.values.value : null,
      commentValue: this.question.values ? this.question.values.comment : null,
      type: this.question.values ? this.question.values.type : null
    };
  },
  created() {
    this.updateAnswer();
  },
  methods: {
    updateAnswer() {
      let answer = {
        id: this.id,
        value: this.value,
        comment: this.commentValue,
        type: this.type
      };
      this.$emit("updateAnswer", answer);
    }
  },
  props: {
    question: {
      formId: String,
      id: Number,
      title: String,
      optional: Boolean,
      help: String,
      placeholder: String
    }
  }
};
</script>