<template>
  <div class="py-4 px-4 w-full">
    <div class="text-left font-bold text-grey-darkest">
      <label class="flex">
        <p>
          {{ question.title }}
          <span
            v-if="question.optional"
            class="font-thin text-sm italic mx-1"
          >- {{$t('inputs.optional')}}</span>
        </p>
        <component
          v-if="question.help"
          :is="'BaseTooltip'"
          :title="question.title"
          :text="question.help"
        />
      </label>
    </div>
    <div id="slider">
      <div>
        <input
          type="number"
          v-model="value"
          class="w-full border border-grey-dark h-10 rounded-sm p-2"
          name
          id="number"
        />
      </div>
    </div>
    <div v-if="question.comment" class="comment text-left py-2">
      <input
        type="text"
        v-model="commentValue"
        class="w-full border border-grey-dark h-10 rounded-sm p-2"
        :placeholder="$t('inputs.comment')"
        @blur="updateAnswer()"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseNumberInput",
  data() {
    return {
      id: this.question.id,
      value: this.question.values ? this.question.values.value : null,
      commentValue: this.question.values ? this.question.values.comment : null,
      type: this.question.values ? this.question.values.type : null,
    };
  },
  watch: {
    value: function () {
      this.updateAnswer();
    },
  },
  created() {
    this.updateAnswer();
  },
  methods: {
    updateAnswer() {
      let answer = {
        id: this.id,
        value: this.value,
        comment: this.commentValue,
        type: this.type,
      };
      this.$emit("updateAnswer", answer);
    },
  },
  props: {
    question: {
      formId: String,
      id: Number,
      title: String,
      optional: Boolean,
      comment: Boolean,
      help: String,
      values: Object,
    },
  },
};
</script>