export default {
    state: {
        sidebarOpen: false,
        showSidebarToggle: true
    },
    mutations: {
        toggleSidebar(state) {
            state.sidebarOpen = !state.sidebarOpen;
        },
        closeSidebar(state) {
            state.sidebarOpen = false;
        },
        openSidebar(state) {
            state.sidebarOpen = true;
        },
        showSidebarToggle(state) {
            state.showSidebarToggle = true;
        },
        hideSidebarToggle(state) {
            state.showSidebarToggle = false;
        }
    },
    actions: {
        toggleSidebar({ commit }) {
            commit('toggleSidebar');
        },
        closeSidebar({ commit }) {
            commit('closeSidebar');
        },
        openSidebar({ commit }) {
            commit('openSidebar');
        },
        showSidebarToggle({ commit }) {
            commit('showSidebarToggle');
        },
        hideSidebarToggle({ commit }) {
            commit('hideSidebarToggle');
        }
    },
    getters: {
        isSidebarOpen: state => state.sidebarOpen,
        showSidebarToggle: state => state.showSidebarToggle
    }
}