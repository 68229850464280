import axios from "axios";
import { apiPostRequest, apiGetRequest } from "./api";

export default {
    state: {
        token: localStorage.getItem("user-token") || null,
        sessionId: null,
        status: null,
        language: localStorage.getItem("user-locale") || "sv",
        userinfo:
            localStorage.getItem("user-info") !== null
                ? JSON.parse(localStorage.getItem("user-info"))
                : {},
        history: null,
        consent: [],
        notificationToken: null,
        notificationPermission: false
    },
    mutations: {
        loginRequest(state) {
            state.status = "loading";
        },
        loginFailed(state) {
            state.status = "error";
        },
        loginSuccess(state, token) {
            state.token = token;
            state.status = "complete";
        },
        loginVerified(state, sessionId) {
            state.sessionId = sessionId;
        },
        logout(state) {
            localStorage.removeItem("user-token");
            localStorage.removeItem("user-info");
            localStorage.removeItem("followupforms");
            delete axios.defaults.headers.common["Authorization"];

            state.token = null;
            state.sessionId = null;
        },
        changeLang(state, lang) {
            localStorage.setItem("user-locale", lang);
            state.language = lang;
        },
        setHistory(state, data) {
            state.history = data;
        },
        setUserInfo(state, data) {
            state.userinfo = data;
            localStorage.setItem("user-info", JSON.stringify(data));
        },
        addConsent(state, caretaker) {
            if (state.consent.indexOf(caretaker) === -1) {
                state.consent.push(caretaker);
            }
        },
        removeConsent(state, caretaker) {
            const idx = state.consent.indexOf(caretaker);
            if (idx !== -1) {
                state.consent.splice(idx, 1);
            }
        },
        setConsent(state, consent) {
            state.consent = consent;
        },
        setNotificationToken(state, token) {
            state.notificationToken = token;
        },
        setNotificationPermission(state, status) {
            state.notificationPermission = status;
        },
        setSessionId(state, sessionId) {
            state.sessionId = sessionId;
        }
    },
    actions: {
        async loginBankId({ commit, dispatch }, nationalId) { //eslint-disable-line
            const data = {
                nationalId: nationalId
            };

            return await apiPostRequest("session/createWithBankId", data)
                .then(data => {
                    return data;
                })
                .catch(error => {
                    localStorage.removeItem("user-token");
                    return error;
                });
        },
        setSessionId({ commit }, sessionId) {
            commit("setSessionId", sessionId);
        },
        async verifyBankIdLogin({ commit, dispatch, state }, sessionId) {
            const data = {
                authtype: "bankid",
                grandidsession: sessionId
            };
            if (!state.sessionId == sessionId) {
                return Promise.reject("Session mismatch");
            }

            return await apiPostRequest("session/setSession", data)
                .then(data => {
                    if (!data.token) {
                        return Promise.reject({
                            status: data,
                            message: "Patient not authorized"
                        });
                    }

                    let jwt = JSON.parse(atob(data.token.split(".")[1]));

                    let patientId = jwt.PatientId;
                    let sessionId = data.sessionId;
                    let startroute = data.patienttype == "adhd" ? "adhdstart" : "start";
                    data.startroute = startroute;
                    let first_name = jwt.FirstName;
                    let last_name = jwt.LastName;

                    localStorage.setItem("user-token", data.token);
                    axios.defaults.headers.common["Authorization"] = `Bearer: ${
                        data.token
                        }`;

                    commit("loginSuccess", data.token);
                    commit("loginVerified", sessionId);

                    commit("setUserInfo", {
                        id: patientId,
                        first_name: first_name,
                        last_name: last_name,
                        patienttype: data.patienttype
                    });
                    // dispatch('getUserInfo', patientId)
                    dispatch("setConsent");

                    return data;
                })
                .catch(error => {
                    commit("logout");

                    return Promise.reject(error);
                });
        },
        logout({ commit, state }) {
            const data = {
                sessionId: state.sessionId
            };

            apiPostRequest("session/logout", data).then(data => { // eslint-disable-line
                commit("logout");
            });
        },
        changeLang({ commit }, lang) {
            commit("changeLang", lang);
        },
        getHistory({ commit }, patientId) {
            apiGetRequest(`history/patient/${patientId}`).then(data => {
                commit("setHistory", data);
            });
        },
        setConsent({ commit }) {
            commit("setConsent", [1773944]);
        },
        addConsent({ commit }, { caretakerId }) {
            commit("addConsent", +caretakerId);
        },
        removeConsent({ commit }, { caretakerId }) {
            commit("removeConsent", +caretakerId);
        },
        async setNotificationToken({ commit }, data) {
            return await apiPostRequest("patients/notificationtoken", data).then(
                data => { //eslint-disable-line
                    commit("setNotificationToken", true);
                }
            );
        },
        setNotificationPermission({ commit }, status) {
            commit("setNotificationPermission", status);
        },
        checkJwtExpiration({ state }) {
            let jwt =
                state.token !== null
                    ? JSON.parse(atob(state.token.split(".")[1]))
                    : null;
            if (jwt !== null) {
                return Promise.resolve(jwt.exp);
            }
            return Promise.reject(false);
        }
    },
    getters: {
        isAuthenticated: state => {
            return state.token === null ? false : true;
        },
        loginStatus: state => state.status,
        userInfo: state => state.userinfo,
        patientId: state => state.userinfo.id,
        consent: state => state.consent,
        getToken: state => state.token,
        notificationPermission: state => state.notificationPermission,
        history: state => state.history
    }
};
