<template>
  <div>
    <div v-if="loaded && !saved" class="bg-base-gray">
      <div
        id="weekHeader"
        class="bg-base-gray w-full text-base-gray-text flex flex-col justify-center"
      >
        <p class="ml-6 pt-5 font-bold text-xl">{{ card.title }}</p>
        <p class="ml-6 pb-5">{{ formatDate(card.day) }}</p>
      </div>
      <div id="questions">
        <div
          class="mb-1 bg-white w-full flex flex-col items-center"
          v-for="(section, i) in card.sections"
          :key="i"
        >
        <div v-if="section.title" class="text-left text-lg w-full font-bold text-grey-darkest px-4 pt-4">
          {{ section.title }}
        </div>
          <component
            :is="getComponent(question.type)"
            v-for="(question) in section.questions"
            :key="question.id"
            :question="question"
            @updateAnswer="updateAnswer"
          />
        </div>
        <div class="-mt-1 bg-white flex flex-col items-center p-4 w-full">
          <button
            :class="(valid && saving == false ? 'bg-base-blue' : 'bg-base-gray')"
            class="w-full p-3 text-white rounded-lg mb-16"
            @click="saveCard()"
            :disabled="valid == false || saving == true"
          >{{$t("card.saveCard")}}</button>
        </div>
      </div>
      <div
        class="fixed bottom-0 w-full h-16 bg-white flex flex-col justify-between overflow-hidden z-50"
      >
        <div class="bg-base-blue h-1" :style="progress" id="progressBar"></div>
        <div class="flex justify-between items-center pb-3 pl-3 pr-3">
          <div>{{$t("card.filledInOf", [answeredQuestions, totalQuestions])}}</div>
          <div
            @click="goBack()"
            class="flex flex-col content-center items-center"
          >
            <img class="h-5" src="/images/times.png" alt srcset />
            <p class="text-xs">{{$t("card.close")}}</p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="saved"
      class="w-full h-full bg-base-gray flex flex-col content-center items-center"
    >
      <img class="h-48 m-10" src="/images/girl.png" alt srcset />
      <p class="text-2xl font-bold text-base-gray-text p-2">{{ card.sentTitle }}</p>
      <p class="text-base-gray-text">{{$t("card.savedCard", [formatDate(dateId)])}}</p>
      <button
        :class="'bg-base-blue p-3 px-6 text-white rounded-lg m-8'"
        @click="$router.push('/weeklycards')"
      >{{$t("card.back")}}</button>
    </div>
    <div v-else class="flex justify-center">
      <font-awesome-icon icon="circle-notch" class="mx-2 text-blue" spin size="2x" />
    </div>
  </div>
</template>
<style scoped>
#progressBar {
  box-shadow: 20000px -20000px 0px 20000px rgba(216, 216, 216, 1);
}
</style>
<script>
import { cardDate } from "../utils/dateformat.js";
import { parseISO } from "date-fns/esm";
export default {
  name: "card",
  data() {
    return {
      dateId: this.$route.params.dateId,
      loaded: false,
      saved: false,
      answers: [],
      saving: false,
    };
  },
  created() {
    this.$store.dispatch("hideSidebarToggle");
    this.$store.dispatch("loadCurrentCard", this.dateId).then(() => {
      this.loaded = true;
    });
  },
  computed: {
    card() {
      return this.$store.getters.getCurrentCard;
    },
    totalQuestions() {
      let total = 0;
      this.card.sections.forEach((section) => {
        section.questions.forEach(() => (total += 1));
      });
      return total;
    },
    answeredQuestions() {
      let answered = 0;
      this.answers.forEach((answer) => {
        if (answer.value !== null && answer.value !== "") answered += 1;
      });
      return answered;
    },
    progress() {
      let progress = Math.round(
        (this.answeredQuestions / this.totalQuestions) * 100
      );
      let style = `width: ${progress}%;`;
      return style;
    },
    valid() {
      let valid = true;
      this.card.sections.forEach((section) => {
        section.questions.forEach((question) => {
          let ans = this.answers.find((a) => a.id === question.id);
          if (!question.optional) {
            if (!ans || ans.value === null || ans.value === "") valid = false;
          }
        });
      });
      return valid;
    },
  },
  methods: {
    getComponent($type) {
      switch ($type) {
        case "range":
          return "BaseSlider";
        case "multiradio":
          return "BaseMultipleRadio";
        case "yesno":
          return "BaseYesNo";
        case "text":
          return "BaseDbtTextarea";
        case "number":
          return "BaseNumberInput";
      }
    },
    updateAnswer(answer) {
      let found = this.answers.find((o) => o.id === answer.id);
      if (found) {
        let newArr = this.answers.map((ans) =>
          answer.id === ans.id ? answer : ans
        );
        this.answers = newArr;
      } else {
        this.answers.push(answer);
      }
    },
    saveCard() {
      if (this.valid) {
        this.saving = true;
        let data = {
          day: this.dateId,
          code: this.card.code,
          answers: this.answers,
        };
        this.$store
          .dispatch("saveCard", data)
          .then(() => {
            this.saved = true;
          })
          .catch(() => {
            alert("Kunde inte spara dagbok");
          });
      } else {
        alert("Du har inte fyllt i alla frågor!");
      }
    },
    formatDate(day) {
      return cardDate(parseISO(day));
    },
    goBack() {
      if(confirm("Är du säker på att du vill stänga kortet utan att spara?")) {
        this.$router.push({name: "weeklyCards"});
      }
    }
  },
};
</script>