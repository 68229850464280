<template>
  <div>
    <div class="h-2 w-full bg-base-gray"></div>
    <div v-if="loaded">
      <div class="m-6">
        <p class="font-semibold text-2xl text-base-gray-text">{{firstName}} {{lastName}}</p>
      </div>
      <div class="settings m-6">
        <div>
          <p class="my-2 text-base-gray-text font-semibold">{{$t("settings.phone")}}</p>
          <input
            type="tel"
            v-model="mobile"
            class="w-full border border-grey-dark h-10 rounded-sm p-2"
            placeholder="XXX-XXX XX XX"
          />
        </div>
      </div>
      <div class="flex flex-col items-center px-6 w-full">
        <button
          :class="'bg-base-blue w-full p-3 text-white rounded-lg'"
          @click="saveSettings()"
        >{{$t("settings.save")}}</button>
      </div>
    </div>
    <div v-else class="flex justify-center">
      <font-awesome-icon icon="circle-notch" class="m-2 text-blue" spin size="2x" />
    </div>
  </div>
</template>
<script>
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
export default {
  name: "Settings",
  data() {
    return {
      firstName: this.$store.getters.userInfo.first_name,
      lastName: this.$store.getters.userInfo.last_name,
      mobile: null,
      loaded: false
    };
  },
  created() {
    this.loadPatient();
  },
  methods: {
    loadPatient() {
      this.$store.dispatch("loadPatient", this.$store.getters.userInfo.id).then(() => {
        this.mobile = this.$store.getters.getPatient.mobile;
        this.loaded = true;
      });
    },
    saveSettings() {
      let valid = this.validateNumber();
      if(valid) {
        let settings = {
          'mobile': this.mobile
        };
        this.$store.dispatch("saveSettings", [this.$store.getters.userInfo.id, settings]).then(() => {
          this.loadPatient();
          this.loaded = false;
        });
      } else {
        alert("Ange ett giltigt mobilnummer");
      }
    },
    validateNumber() {
      let phoneUtil = PhoneNumberUtil.getInstance();
      let number;
      if(this.mobile && this.mobile.length > 1) {
        number = phoneUtil.parse(this.mobile, "SE");
        if(phoneUtil.isValidNumber(number)) {
          this.mobile = phoneUtil.format(number, PhoneNumberFormat.E164);
          return true;
        }
      }
      return false;
    }
  },
};
</script>