import { apiPostRequest, apiGetRequest } from "./api";

export default {
    state: {
        currentWeeklyCards: null,
        currentCard: null,
        meta: null
    },
    mutations: {
        setCurrentWeeklyCards(state, data) {
            state.currentWeeklyCards = data;
        },
        setCurrentCard(state, data) {
            state.currentCard = data;
        },
        setMeta(state, data) {
            state.meta = data;
        }
    },
    actions: {
        async loadCurrentWeeklyCards({ commit }) {
            await apiGetRequest(`card/current`).then(data => {
                commit("setCurrentWeeklyCards", data);
            });
        },
        async loadCurrentCard({ commit }, date) {
            await apiGetRequest(`card/get/${date}`).then(data => {
                commit("setCurrentCard", data);
            });
        },
        async loadMeta({ commit }) {
            await apiGetRequest(`card/meta`).then(data => {
                commit("setMeta", data);
            });
        },
        async saveCard({ commit }, data) { // eslint-disable-line
            await apiPostRequest(`card/save`, data)
                .then(response => {
                    return response.data
                });
        }
    },
    getters: {
        getCurrentWeeklyCards: state => state.currentWeeklyCards,
        getCurrentCard: state => state.currentCard,
        getMeta: state => state.meta
    }
}