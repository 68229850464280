export default {
    sv: {
        button: {
            menu: "Meny"
        },
        inputs: {
            optional: "Valfri",
            comment: "Kommentar",
            yes: "Ja",
            no: "Nej"
        },
        sidebar: {
            report: "Rapportera dagbok",
            weeklycards: "Veckokort",
            files: "Dokument/Program",
            information: "Information",
            settings: "Inställningar",
            logout: "Logga ut",
            close: "Stäng"
        },
        card: {
            close: "Stäng",
            filledInOf: "Fyllt i {0} av {1}",
            saveCard: "Spara i min dagbok",
            savedCard: "Du har nu sparat dagbok för {0}",
            back: "Tillbaka till veckokort"
        },
        information: {
            contactdetails: "Kontaktuppgifter",
            times: "Telefontider",
            emergency: "Journummer",
            aroundtheclock: "dygnet runt",
            empty: "Din behandlare har inte skrivit något här."
        },
        dayitem: {
            fillout: "Fyll i DBT-dagbok",
            filledout: "Ifylld {0}"
        },
        files: {
            document: "Dokument",
            program: "Program för gruppsessioner",
            general: "Allmänt om DBT",
            info: "Länk till extern sida med info",
            exercises: "Övningar"
        },
        settings: {
            phone: "Mobilnummer",
            email: "E-postadress",
            save: "Spara"
        },
        weeklycards: {
            week: "Vecka {0}",
            noactiveperiod: "Ingen aktiv period"
        },
        login: {
            bankid: "Logga in med BankID",
            text: "Logga in för att komma åt hela appen för att dela dina resultat med din mottagning."
        },
        placeholder: {
            input: {
                ssn: "Ange personnummer"
            }
        }
    }
}