import axios from 'axios'

const apiGetRequest = (url) => {
    return axios.get(`${process.env.VUE_APP_API_URL}/${url}`)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

const apiPostRequest = (url, data) => {
    return axios.post(`${process.env.VUE_APP_API_URL}/${url}`, data)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

export {
    apiGetRequest,
    apiPostRequest
}