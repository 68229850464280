<script>
export default {
    name: 'LoginVerified',
    mounted () {
        this.$store.dispatch('verifyBankIdLogin', this.$route.query.grandidsession)
            .then(response => { // eslint-disable-line
                this.$router.replace('/weeklycards')
                //this.$router.replace({ name: response.startroute})
            })
            .catch(error => { //eslint-disable-line
                this.$router.push({ name: 'login', params: { errorMsg: this.$t('login.error.unauthorized') }})
            })
    },
    render (createElement, context) { //eslint-disable-line
        return createElement('div')
    }
}
</script>