import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import WeeklyCards from "@/components/WeeklyCards.vue";
import Card from "@/components/Card.vue";
import Files from "@/components/Files.vue";
import Information from "@/components/Information.vue";
import Settings from "@/components/Settings.vue";
import Login from "@/components/Login.vue";
import LoginVerified from "@/components/LoginVerified.vue";
import Logout from "@/components/Logout.vue";

let routes = [
    {
        path: "/login",
        name: "login",
        component: Login,
        props: { errorMsg: null }
    },
    {
        path: "/loginVerified",
        name: "loginVerified",
        component: LoginVerified
    },
    {
        path: "/logout",
        name: "logout",
        component: Logout
    },
    {
        path: "/weeklycards",
        name: "weeklyCards",
        component: WeeklyCards,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/card/:dateId",
        name: "card",
        component: Card,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/files",
        name: "files",
        component: Files,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/information",
        name: "information",
        component: Information,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/settings",
        name: "settings",
        component: Settings,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "*",
        redirect: "/weeklycards"
    }
];

export const router = new VueRouter({
    mode: "history",
    routes: routes
});

router.beforeEach((to, from, next) => {
    const authRequired = to.meta.requireAuth && to.meta.requireAuth == true;
    const loggedIn = localStorage.getItem("user-token");

    if (authRequired && !loggedIn) {
        return next("/login");
    }

    next();
})