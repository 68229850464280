<template>
  <router-link v-bind:to="{ name: 'card', params: { dateId: id } }">
    <div class="flex border-b p-2 justify-between items-center">
      <div>
        <img
          :src="card.created ? '/images/answered.png' : '/images/unanswered.png'"
          class="w-12"
          alt
        />
      </div>
      <div class="text-left w-full ml-2">
        <p class="font-bold text-base-gray-text">{{ formatDay(card.day) }}</p>
        <p
          class="font-medium text-sm text-gray-text"
        >{{ card.created ? ($t('dayitem.filledout', [card.created])) : ($t("dayitem.fillout")) }}</p>
      </div>
      <div>
        <img src="/images/arrow-right.png" class="w-4" alt srcset />
      </div>
    </div>
  </router-link>
</template>
<script>
import { cardDate } from "../utils/dateformat.js";
import { parseISO } from "date-fns/esm";
export default {
  name: "DayItem",
  props: {
    card: Object,
    id: String
  },
  methods: {
    formatDay(day) {
      return cardDate(parseISO(day));
    }
  }
};
</script>