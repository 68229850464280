<template>
  <div class="w-full flex justify-between items-center p-4">
    <div>
      <img @click="goBack()" src="/images/arrow-left.png" class="h-6" alt srcset />
    </div>
    <div>
      <img src="/images/kanslobarometern.png" alt="Region Halland" srcset class="w-40" />
    </div>
    <div></div>
  </div>
</template>
<style scoped>
</style>
<script>
export default {
  name: "Header",
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>